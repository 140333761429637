import React from 'react';
import { Helmet } from 'react-helmet-async';
import OurProjects from '../components/Projects';

const Projects = () => {
  return (
    <>
      <Helmet>
        <title>Projects | Conversion Lab</title>
        <meta name="description" content="Learn more about Conversion Lab and our mission to deliver high-quality web development and brand management solutions." />
      </Helmet>
      <OurProjects />
    </>
  );
};

export default Projects;
