import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../css/Main.css'; 
import logo from '../assets/logo.png'; 
import logowhite from '../assets/logowhite.svg'; 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import { Link, useLocation } from 'react-router-dom';
import Calendar from '../assets/calendar.svg';
import { Accordion } from 'react-bootstrap';
import Check from '../assets/check.svg';
import FourStepProcess from '../components/FourStageProcess.js';
import Gluteflex from '../assets/Portfolio/gluteflex-main.png';

const BrandManagement = () => {
  return (
    <>
      <Helmet>
        <title>GluteFlex | Conversion Lab</title>
        <meta name="description" content="" />
      </Helmet>
      <div className='portfolio-page'>
      <div className='page-header p-5'>
        <div className='row pt-1'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3'>
                <div className='portfolio-box-img'>
                <img src={Gluteflex} width="100%" alt="Gluteflex Portfolio Image"></img>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3'>
            <div className='portfolio-box-text'>
                                <span className='span-tag2'>CASE STUDY</span>
                                <h3>Shopify Design and Meta Ads Management for GluteFlex</h3>
                                <p>Our CRO-focused Shopify store and strategic Meta Ads drove £25k in sales within the first month, maximising conversions for this fitness tool.</p>
                                <div className='portfolio-box-stats'>
                                    <div className='portfolio-stat'>
                                        <h2>20%</h2>
                                        <p>Increase in conversion rate</p>
                                    </div>
                                    <div className='portfolio-stat'>
                                        <h2>2X</h2>
                                        <p>Increase in meta ROAS</p>
                                    </div>
                                </div>
                            </div>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3'>
                <div className='portfolio-box-text'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto'>
                            <div className=' text-center portfolio-page-desc'>
                            <p>GluteFlex is an innovative at-home fitness tool specifically designed for women who want to focus on glute workouts. This portable device can easily suction to any flat surface, making it a versatile solution for fitness enthusiasts looking to stay in shape without the need for expensive gym equipment. Built with convenience in mind, GluteFlex allows users to work out anywhere and anytime, empowering women to achieve their fitness goals from the comfort of their own homes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>

<div className='container pt-2 service-page-bg2 pb-4'>
    <div className="service-page-process">
    <div className='row'>
    <div className='col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto'>
        <strong>Challenge</strong>
        <li>The inspiration for GluteFlex arose from recognizing a gap in the market for an affordable and effective at-home workout tool tailored for women. The challenge was to create a product that was not only accessible but also highly functional.</li>

        <strong>Solution</strong>
        <li>The development and marketing strategy for GluteFlex involved several key components:</li>

        <strong>Product Development:</strong>
        <li>Designed an efficient tool focusing on portability, suction capability, and adjustable resistance levels, ensuring it meets the unique needs of the target audience.</li>

        <strong>Streamlined Online Store:</strong>
        <li>Built on Shopify, the online store was optimized for ease of purchase, providing customers with a simple and intuitive shopping experience.</li>

        <strong>Targeted Marketing Strategy:</strong>
        <li>Launched a robust marketing campaign on social media platforms, connecting directly with women interested in fitness. Highlighted key features such as portability and versatility in engaging content to draw attention to the product's effectiveness.</li>

        <strong>Results:</strong>
        <li>The outcomes of these efforts were impressive:</li>
        <li>Within the first week of its launch, GluteFlex generated £15,000 in sales, demonstrating significant market demand and the product's value to consumers.</li>
        <li>The marketing strategy successfully reached and engaged the target audience, leading to increased brand awareness and customer interest.</li>

        <strong>Testimonial:</strong>
        <li>"GluteFlex has filled a significant gap in the market for effective at-home glute workouts. The marketing strategy and online store setup have made it incredibly easy for customers to access our product, and the response has been overwhelmingly positive!"</li>
        <p>Charlie G, Founder of GluteFlex</p>
    </div>
    </div>
    </div>
</div>


    </>
  );
};

export default BrandManagement;
