import React from 'react';
import { Helmet } from 'react-helmet-async';
import ContactForm from '../components/ContactPage';
import { InlineWidget } from 'react-calendly';

const Contact = () => {
  return (
    <>
    
      <Helmet>
        <title>Contact Us | Conversion Lab</title>
        <meta name="description" content="Get in touch with Conversion Lab. We are here to answer your questions and discuss how we can help your business." />
      </Helmet>
      <div className='container'>
        <div className='about-hero '>
          <h2>Want to <span>contact us!</span></h2>
        </div>
      </div>
      <div className="container contactpage">
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12'>
          <ContactForm />
            </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
