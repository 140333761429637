import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import '../css/Projects.css' 
import '../css/Main.css' 
import { Accordion } from 'react-bootstrap';
import Convertlab from '../assets/Portfolio/convertlab-main.png'
import Dalle from '../assets/Portfolio/dalle-designs-main.png'
import Gluteflex from '../assets/Portfolio/gluteflex-main.png'
import Nosnore from '../assets/Portfolio/nosnore-main.png'
import arrowup from '../assets/arrow-trend-up-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Services = () => {
  useEffect(() => {
    AOS.init({
      once: true, 
    });
  }, []);
  return (
    <div>
      <div className='container pb-5'>
        <div className='about-hero'>
          <h2>Check out our work with <span>Real Clients</span></h2>
        </div>
      </div>

        <div className='container projects-portfolio pb-3' data-aos="fade-up" data-aos-delay="00">
            <Link to="/no-snore" className="portfolio-link">
                <div className='portfolio-box' data-aos="fade-up">
                    <div className='row'>
                        <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12'>
                            <div className='portfolio-box-img'>
                                <img src={Nosnore} width="100%" alt="Nosnore Portfolio Image"></img>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12 mt-lg-0 mt-md-2 mt-sm-2 mt-xs-2 mt-2'>
                            <div className='portfolio-box-text'>
                                    <span className='span-tag2'>CASE STUDY</span>
                                    <h3>Shopify Design & Ad Campaigns for No-Snore</h3>
                                    <p>How our Shopify design and targeted Meta and Google Ads drove £500k in sales within 9 months.</p>
                                    <div className='portfolio-box-stats mt-auto'>
                                        <div className='portfolio-stat'>
                                            <h2>£500k+</h2>
                                            <p>Revenue generated</p>
                                        </div>
                                        <div className='portfolio-stat'>
                                            <h2>50%</h2>
                                            <p>Increase in conversion</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </Link>
        </div>

        <div className='container projects-portfolio pb-3' data-aos="fade-up" data-aos-delay="00">
            <Link to="/glute-flex" className="portfolio-link">
                <div className='portfolio-box' data-aos="fade-up">
                    <div className='row'>
                        <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12'>
                            <div className='portfolio-box-img'>
                                    <img src={Gluteflex} width="100%" alt="Gluteflex Portfolio Image"></img>
                                </div>
                        </div>
                        <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12 mt-lg-0 mt-md-2 mt-sm-2 mt-xs-2 mt-2'>
                        <div className='portfolio-box-text'>
                                <span className='span-tag2'>CASE STUDY</span>
                                <h3>Shopify Design and Meta Ads Management for GluteFlex</h3>
                                <p>Our CRO-focused Shopify store and strategic Meta Ads drove £25k in sales within the first month, maximising conversions for this fitness tool.</p>
                                <div className='portfolio-box-stats'>
                                    <div className='portfolio-stat'>
                                        <h2>20%</h2>
                                        <p>Increase in conversion rate</p>
                                    </div>
                                    <div className='portfolio-stat'>
                                        <h2>2X</h2>
                                        <p>Increase in meta ROAS</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>

        <div className='container projects-portfolio pb-3' data-aos="fade-up" data-aos-delay="00">
            <Link to="/dalle-designs" className="portfolio-link">
                <div className='portfolio-box' data-aos="fade-up">
                    <div className='row'>
                        <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12'>
                            <div className='portfolio-box-img'>
                                <img src={Dalle} width="100%" alt="Dall-E Designs Portfolio Image"></img>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12 mt-lg-0 mt-md-2 mt-sm-2 mt-xs-2 mt-2'>
                                <div className='portfolio-box-text'>
                                        <span className='span-tag2'>CASE STUDY</span>
                                        <h3>Fully Custom Web Design & Development for Dall-E Designs</h3>
                                        <p>Bespoke PHP front-end and back-end solution built with MySQL, featuring Figma-designed interfaces, Stripe-powered checkout, and seamless OpenAI integration.</p>

                                        <div className='portfolio-box-stats'>
                                            <div className='portfolio-stat'>
                                                <h2>60%</h2>
                                                <p>Increase in website traffic</p>
                                            </div>
                                            <div className='portfolio-stat'>
                                                <h2>100%</h2>
                                                <p>Increase in conversion rate</p>
                                            </div>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>

    </div>
  );
};

export default Services;
