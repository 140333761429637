import React, { useState, useEffect } from 'react';
import '../css/Main.css'; // Import CSS

const MouseFollower = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [ballPosition, setBallPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const scrollX = window.pageXOffset; // Get horizontal scroll position
      const scrollY = window.pageYOffset; // Get vertical scroll position

      setPosition({
        x: e.clientX + scrollX, // Adjust X position for scroll
        y: e.clientY + scrollY, // Adjust Y position for scroll
      });
    };

    // Add event listener for mouse move
    window.addEventListener('mousemove', handleMouseMove);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    let animationFrameId;

    const followMouse = () => {
      setBallPosition((prevPosition) => ({
        x: prevPosition.x + (position.x - prevPosition.x) * 0.1, // Smooth transition on X axis
        y: prevPosition.y + (position.y - prevPosition.y) * 0.1, // Smooth transition on Y axis
      }));
      animationFrameId = requestAnimationFrame(followMouse); // Request next frame
    };

    followMouse(); // Start animation

    return () => cancelAnimationFrame(animationFrameId); // Clean up on unmount
  }, [position]);

  return (
    <div
      className="ball"
      style={{ transform: `translate(${ballPosition.x}px, ${ballPosition.y}px)` }}
    ></div>
  );
};

export default MouseFollower;
