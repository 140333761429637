import React from 'react';
import { Helmet } from 'react-helmet-async';
import AllReviews from '../components/AllReviews';

const Projects = () => {
  return (
    <>
      <Helmet>
        <title>Reviews | Conversion Lab</title>
        <meta name="description" content="Learn more about Conversion Lab and our mission to deliver high-quality web development and brand management solutions." />
      </Helmet>
      <AllReviews />
    </>
  );
};

export default Projects;