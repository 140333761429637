import React from 'react';

const TermsConditions = () => {
  return (
    <div className="container policies  py-5">
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to ConversionLab. These terms and conditions outline the rules and regulations for the use of our website and services. By accessing or using our website, you agree to comply with and be bound by these terms. If you disagree with any part of the terms, please do not use our services.
      </p>

      <h2>1. Definitions</h2>
      <p>
        <strong>“We,” “Us,” “Our”</strong> refers to ConversionLab, the provider of the services. 
        <strong>“You,” “Your,”</strong> refers to the user or client of our services and website.
      </p>

      <h2>2. Use of Services</h2>
      <p>
        Our services are intended for professional use in web design, digital marketing, and related fields. You agree not to use our services for any illegal or unauthorized purposes. You must not violate any laws in your jurisdiction while using our services.
      </p>

      <h2>3. Intellectual Property</h2>
      <p>
        All content, branding, logos, and other materials found on our website are owned by ConversionLab unless stated otherwise. Unauthorized reproduction, distribution, or use of any content is prohibited. You may not use any of our intellectual property without prior written consent.
      </p>

      <h2>4. Payment and Fees</h2>
      <p>
        Payments for services are required upfront as per the pricing details outlined on our website or in the contract agreement. If you fail to make payment by the due date, we reserve the right to suspend or terminate services. Late fees may apply if invoices are not settled within the agreed timeframe.
      </p>

      <h2>5. Refunds and Cancellations</h2>
      <p>
        We offer a 14-day cooling-off period after purchasing our services. If you cancel within this period, a refund may be issued, minus any costs already incurred for services rendered. After the cooling-off period, no refunds will be provided unless explicitly agreed in writing.
      </p>

      <h2>6. Limitation of Liability</h2>
      <p>
        To the maximum extent permitted by law, ConversionLab shall not be held liable for any damages, including direct, indirect, incidental, or consequential damages resulting from the use or inability to use our services. Our total liability to you shall not exceed the amount paid for the services rendered.
      </p>

      <h2>7. Indemnity</h2>
      <p>
        You agree to indemnify and hold ConversionLab, its affiliates, employees, and agents harmless from any claims, damages, or losses arising out of your use of our services or violation of these terms.
      </p>

      <h2>8. Confidentiality</h2>
      <p>
        Both parties agree to treat any shared confidential information with care and not to disclose it to third parties without prior consent. This includes business details, client information, and any sensitive data shared during the service process.
      </p>

      <h2>9. Modifications to Services</h2>
      <p>
        We reserve the right to modify or discontinue any part of our services at any time without notice. We will not be held liable to you or any third party for any changes, suspension, or termination of services.
      </p>

      <h2>10. Third-Party Tools</h2>
      <p>
        We may provide access to third-party tools or services (e.g., hosting providers, payment gateways). We are not responsible for these third-party tools, and your use of them is entirely at your own risk and discretion.
      </p>

      <h2>11. Governing Law</h2>
      <p>
        These terms and conditions shall be governed and construed in accordance with the laws of the United Kingdom. Any disputes arising out of or in connection with these terms shall be resolved under UK law.
      </p>

      <h2>12. Changes to These Terms</h2>
      <p>
        ConversionLab reserves the right to update or modify these terms at any time. Changes will be posted on this page, and your continued use of our services following such changes constitutes acceptance of the updated terms.
      </p>

      <h2>13. Contact Us</h2>
      <p>
        If you have any questions regarding these Terms and Conditions, please contact us at: <strong>studio@conversionlab.io</strong>.
      </p>
    </div>
  );
};

export default TermsConditions;
