import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import '../css/Brands.css' 
import '../css/Main.css' 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Kitchensmart from '../assets/KSLogo.svg';
import Nosnore from '../assets/nosnore.svg'
import Gluteflex from '../assets/gluteflex.avif'
import Bd from '../assets/bd.png'

const Brands = () => {
    
        const carouselRef = useRef(null);
        let scrollSpeed = 0.5; // Adjust the scroll speed
      
        useEffect(() => {
          const carousel = carouselRef.current;
          let currentScrollPosition = 0;
          let requestId;
          AOS.init({
            once: true,  // Animations will happen only once
          });
      
          // Start scrolling the carousel
          const startScroll = () => {
            if (carousel) {
              currentScrollPosition -= scrollSpeed;
              carousel.style.transform = `translateX(${currentScrollPosition}px)`;
      
              const carouselWidth = carousel.scrollWidth / 2;
      
              // Reset scroll position once half of the carousel has scrolled
              if (Math.abs(currentScrollPosition) >= carouselWidth) {
                currentScrollPosition = 0; // Reset the position smoothly
              }
            }
      
            requestId = requestAnimationFrame(startScroll);
          };
      
          // Start the scrolling animation
          requestId = requestAnimationFrame(startScroll);
      
          // Clean up animation frame when component unmounts
          return () => cancelAnimationFrame(requestId);
        }, [scrollSpeed]);

  return (
    <div className='brands pt-5'>
        <div className="container mb-5">
          <div className='row'>
            <div  data-aos="fade-up" className='col-12 text-lg-end text-md-end text-center'>
              <h1><span className='span-tag2'>WHAT WE DO</span>Who have we worked with?</h1>
            </div>
            <div className='col-12 text-lg-start text-md-start text-center mt-3'>
                <div className='brands-outter-box'>
                    <div className='brand-carousel' ref={carouselRef}>
                        <div className='brand-image'>
                            <img src={Kitchensmart} width='100%' alt='Kitchensmart' />
                        </div>
                        <div className='brand-image'>
                            <img src={Nosnore} width='100%' alt='Nosnore' />
                        </div>
                        <div className='brand-image'>
                            <img src={Gluteflex} width='100%' alt='Gluteflex' />
                        </div>
                        <div className='brand-image'>
                            <img src={Bd} width='100%' alt='Bd' />
                        </div>
                        <div className='brand-image'>
                            <img src={Kitchensmart} width='100%' alt='Kitchensmart' />
                        </div>
                        <div className='brand-image'>
                            <img src={Nosnore} width='100%' alt='Nosnore' />
                        </div>
                        <div className='brand-image'>
                            <img src={Gluteflex} width='100%' alt='Gluteflex' />
                        </div>
                        <div className='brand-image'>
                            <img src={Bd} width='100%' alt='Bd' />
                        </div>
                        <div className='brand-image'>
                            <img src={Kitchensmart} width='100%' alt='Kitchensmart' />
                        </div>
                        <div className='brand-image'>
                            <img src={Nosnore} width='100%' alt='Nosnore' />
                        </div>
                        <div className='brand-image'>
                            <img src={Gluteflex} width='100%' alt='Gluteflex' />
                        </div>
                        <div className='brand-image'>
                            <img src={Bd} width='100%' alt='Bd' />
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>


        <div className='container pb-5'>
            <div className='row text-center p-0 m-0 justify-content-center'>

            <div data-aos="fade-up" data-aos-delay="100" className='col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6 brands-stat-circle-outside1' style={{ padding: 0 }}>
                <div className='brands-stat-circle'>
                    <h3>200%</h3>
                    <p>Average Increase in Traffic</p>
                </div>
                </div>

                <div data-aos="fade-up" data-aos-delay="200" className='col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6 brands-stat-circle-outside2' style={{ padding: 0 }}>
                <div className='brands-stat-circle'>
                    <h3>10x</h3>
                    <p>ROI on Ad Campaigns</p>
                </div>
                </div>

                <div data-aos="fade-up" data-aos-delay="400" className='col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6 brands-stat-circle-outside3' style={{ padding: 0 }}>
                <div className='brands-stat-circle'>
                    <h3>99%</h3>
                    <p>Client Retention Rate</p>
                </div>
                </div>

                <div data-aos="fade-up" data-aos-delay="600" className='col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6 brands-stat-circle-outside4' style={{ padding: 0 }}>
                <div className='brands-stat-circle'>
                    <h3>50M+</h3>
                    <p>Ad Impressions Generated</p>
                </div>
                </div>


            </div>
        </div>

        <div className='container pb-5'>
            <div className='row m-auto justify-content-center'>
            <div className='col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12 mb-2' data-aos="fade-up" data-aos-delay="0">
              <div className='our-benefits-box'>
                    <div className='our-benefits-box-head'>
                        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9996 10.9999L3.49964 20.4999M14.0181 3.53838C15.2361 4.34658 16.4068 5.29941 17.5008 6.3934C18.6042 7.49683 19.564 8.67831 20.3767 9.90766M9.2546 7.89605L6.37973 6.93776C6.04865 6.8274 5.68398 6.89763 5.41756 7.12306L2.56041 9.54065C1.97548 10.0356 2.14166 10.9775 2.86064 11.2424L5.56784 12.2398M11.6807 18.3524L12.6781 21.0596C12.943 21.7786 13.8849 21.9448 14.3798 21.3599L16.7974 18.5027C17.0228 18.2363 17.0931 17.8716 16.9827 17.5405L16.0244 14.6657M19.3482 2.27063L14.4418 3.08838C13.9119 3.17668 13.426 3.43709 13.0591 3.82932L6.446 10.8985C4.73185 12.7308 4.77953 15.5924 6.55378 17.3667C8.32803 19.1409 11.1896 19.1886 13.022 17.4744L20.0911 10.8614C20.4834 10.4944 20.7438 10.0085 20.8321 9.47869L21.6498 4.57222C21.8754 3.21858 20.7019 2.04503 19.3482 2.27063Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className='our-benefits-box-text'>
                        <h3>Proven Track Record</h3>
                        <p>We’ve earned a reputation for driving measurable success through proven strategies that elevate brands.</p>
                    </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12 mb-2' data-aos="fade-up" data-aos-delay="200">
              <div className='our-benefits-box'>
                    <div className='our-benefits-box-head'>
                        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9995 12.0001H12.0095M15.535 15.5357C10.8488 20.222 5.46685 22.438 3.51423 20.4854C1.56161 18.5328 3.77769 13.1509 8.46398 8.46461C13.1503 3.77832 18.5322 1.56224 20.4848 3.51486C22.4374 5.46748 20.2213 10.8494 15.535 15.5357ZM15.535 8.46443C20.2213 13.1507 22.4374 18.5326 20.4848 20.4852C18.5321 22.4379 13.1502 20.2218 8.46394 15.5355C3.77765 10.8492 1.56157 5.4673 3.51419 3.51468C5.46681 1.56206 10.8487 3.77814 15.535 8.46443ZM12.4995 12.0001C12.4995 12.2763 12.2757 12.5001 11.9995 12.5001C11.7234 12.5001 11.4995 12.2763 11.4995 12.0001C11.4995 11.724 11.7234 11.5001 11.9995 11.5001C12.2757 11.5001 12.4995 11.724 12.4995 12.0001Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className='our-benefits-box-text'>
                        <h3>Tailored Solutions</h3>
                        <p>We design strategies that align perfectly with your business goals, audience, and industry specifics..</p>
                    </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12 mb-2' data-aos="fade-up" data-aos-delay="400">
              <div className='our-benefits-box'>
                    <div className='our-benefits-box-head'>
                        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className='our-benefits-box-text'>
                        <h3>Client-Centric Focus</h3>
                        <p>Your success is our main focus. We immerse ourselves in understanding your business to ensure you achieve your objectives.</p>
                    </div>
              </div>
            </div>
            </div>

            <div className='benefits-container pb-5'>

                <div className=''>
                    <div className='our-benefits-2' data-aos="fade-up" data-aos-delay="0">
                        <p>
                            <span className="svg-icon">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2V3M3 12H2M5.5 5.5L4.8999 4.8999M18.5 5.5L19.1002 4.8999M22 12H21M10 13.5H14M12 13.5V18.5M15.5 16.874C17.0141 15.7848 18 14.0075 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 14.0075 6.98593 15.7848 8.5 16.874V18.8C8.5 19.9201 8.5 20.4802 8.71799 20.908C8.90973 21.2843 9.21569 21.5903 9.59202 21.782C10.0198 22 10.5799 22 11.7 22H12.3C13.4201 22 13.9802 22 14.408 21.782C14.7843 21.5903 15.0903 21.2843 15.282 20.908C15.5 20.4802 15.5 19.9201 15.5 18.8V16.874Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </span>
                            Relentless Innovation
                        </p>
                    </div>
                </div>

                <div className=''>
                    <div className='our-benefits-2' data-aos="fade-up" data-aos-delay="100">
                        <p>
                            <span className="svg-icon">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.46445 8.46448L4.92893 4.92896M4.92893 19.0711L8.46448 15.5355M15.5355 15.5355L19.0711 19.071M19.0711 4.92891L15.5355 8.46445M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </span>
                            Personalised Support
                        </p>
                    </div>
                </div>

                <div className=''>
                    <div className='our-benefits-2' data-aos="fade-up" data-aos-delay="200">
                        <p>
                            <span className="svg-icon">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.2495 2H8.49395C8.31447 2 8.22473 2 8.14551 2.02733C8.07544 2.05149 8.01163 2.09093 7.95868 2.14279C7.89881 2.20143 7.85868 2.2817 7.77841 2.44223L3.57841 10.8422C3.38673 11.2256 3.29089 11.4173 3.31391 11.5731C3.33401 11.7091 3.40927 11.8309 3.52197 11.9097C3.65104 12 3.86534 12 4.29395 12H10.4995L7.49953 22L19.6926 9.35531C20.104 8.9287 20.3097 8.7154 20.3217 8.53288C20.3321 8.37446 20.2667 8.22049 20.1454 8.11803C20.0057 8 19.7094 8 19.1167 8H11.9995L14.2495 2Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </span>
                            Proven Client Success
                        </p>
                    </div>
                </div>

                <div className=''>
                    <div className='our-benefits-2' data-aos="fade-up" data-aos-delay="300">
                        <p>
                            <span className="svg-icon">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 12C2 17.5228 6.47715 22 12 22C13.6569 22 15 20.6569 15 19V18.5C15 18.0356 15 17.8034 15.0257 17.6084C15.2029 16.2622 16.2622 15.2029 17.6084 15.0257C17.8034 15 18.0356 15 18.5 15H19C20.6569 15 22 13.6569 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7C15.4477 7 15 7.44772 15 8C15 8.55228 15.4477 9 16 9Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </span>
                            Consistent Focus on Quality
                        </p>
                    </div>
                </div>

                



            </div>

            <div className='brands-button text-center'>
            <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            GET YOUR FREE CONSULTATION <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={arrowup} width="20px " alt='calendar-icon'></img>
                          </Link>
            </div>
        </div>

    </div>

  );
};

export default Brands;
