import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import '../css/Hero.css' 
import '../css/Main.css' 
import campaign from '../assets/Campaign.png'
import arrowup from '../assets/arrow-trend-up-solid.svg';
import star from '../assets/star-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Hero = () => {
  
    return (
      <div className='hero pb-lg-5 pt-lg-5'>
          <div className="text-center d-flex justify-content-center align-items-center pt-5 pb-5">
              <div className='row pb-5'>
                  <div className='col-lg-10 col-md-11 m-auto pb-5'>
                      <div className="container pt-lg-5 ps-lg-5 ps-md-3 pe-lg-5 pe-md-3 pb-lg-5">
                          <small><span>
                              <img src={star} width="20px" alt='review-star'></img>
                              <img src={star} width="20px" alt='review-star'></img>
                              <img src={star} width="20px" alt='review-star'></img>
                              <img src={star} width="20px" alt='review-star'></img>
                              <img src={star} width="20px " alt='review-star'></img>
                              <span className="hero-star-text">Used to Scale 6-8 Figure Businesses</span>
                          </span></small>
                          <h1  className="display-3 fw-bold mb-2 mt-2">The <span className='hero-text-bg'>Digital</span> Agency <span className='campaign-circle'><img src={campaign} width="60px" alt='campaign-icon'></img></span> That Helps Businesses.</h1>
                          <p  className="lead mb-5">
                          We’re a creative web design, development, and brand management agency in the UK. We build standout websites and run ad campaigns for brands that refuse to blend in.
                          </p>
                          <Link
                            to='/book-a-meeting'
                            className="btn"
                          >
                            GET YOUR FREE CONSULTATION <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={arrowup} width="20px " alt='calendar-icon'></img>
                          </Link>

                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
  };

export default Hero;
