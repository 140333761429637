import React from 'react';
import '../css/AnnouncementBar.css'; // We'll use the CSS you provided

const AnnouncementBar = () => {
  return (
    <div className="announcement-bar">
      <div className="container">
        🚨 Last Call to Get Q4 Ready – Make Your Websites Now for Big Wins!
        <a href="/sale">SALE ENDS TONIGHT.</a>
      </div>
    </div>
  );
};

export default AnnouncementBar;
