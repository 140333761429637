import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import '../css/Faq.css' 
import '../css/Main.css' 
import { Accordion } from 'react-bootstrap';

import arrowup from '../assets/arrow-trend-up-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const FourStageProcess = () => {
  useEffect(() => {
    AOS.init({
      once: true, 
    });
  }, []);
  return (
    <div className='faq'>
    <div className='pt-5 pb-5'>
        <div className='container about-title'>
                <div className='row'>
                    <div  data-aos="" className='col-12 text-lg-start text-md-start text-center mb-3'>
                    <h1><span className='span-tag2'>FAQ</span>Got Questions?<br></br>
                    We've Got Answers!</h1>
                    </div>
                </div>
            </div>

        <div className="col-lg-10 col-md-11 col-sm-12 col-xs-12 col-12 m-auto process-steps" data-aos="fade-up" data-aos-delay="00">
        <Accordion defaultActiveKey="0">
  {/* FAQ 1 */}
  <Accordion.Item eventKey="0">
    <Accordion.Header>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>01.</span> Can I manage my website and marketing on my own?
    </Accordion.Header>
    <Accordion.Body>
      Absolutely, you can! But is that really the best use of your time and resources? <span style={{ color: '#f1f7f6' }}>Conversion Lab</span> is here to take the weight off your shoulders. We have the expertise, the tools, and the creative team to get your business where it needs to be—faster and more effectively than going solo.
    </Accordion.Body>
  </Accordion.Item>

  {/* FAQ 2 */}
  <Accordion.Item eventKey="1">
    <Accordion.Header>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>02.</span> Why invest in professional web design?
    </Accordion.Header>
    <Accordion.Body>
      Your website isn’t just an online brochure—it’s your digital storefront. A professionally designed site by <span style={{ color: '#f1f7f6' }}>Conversion Lab</span> means your business looks sharp, performs optimally, and is equipped to convert visitors into loyal customers. Think of it as an investment in your brand’s long-term success.
    </Accordion.Body>
  </Accordion.Item>

  {/* FAQ 3 */}
  <Accordion.Item eventKey="2">
    <Accordion.Header>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>03.</span> I need more customers—how can you help?
    </Accordion.Header>
    <Accordion.Body>
      Attracting new customers is our specialty! We don’t just drive traffic; we bring the right people to your business. Our proven strategies—spanning web design, SEO, ads, and social media—will help you reach, engage, and convert your ideal audience. With <span style={{ color: '#f1f7f6' }}>Conversion Lab</span>, you're not just getting more clicks—you’re getting real business growth.
    </Accordion.Body>
  </Accordion.Item>

  {/* FAQ 4 */}
  <Accordion.Item eventKey="3">
    <Accordion.Header>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>04.</span> What makes your ad creation services different?
    </Accordion.Header>
    <Accordion.Body>
      We don’t believe in cookie-cutter ads. Our team at <span style={{ color: '#f1f7f6' }}>Conversion Lab</span> takes the time to understand your brand and craft unique, high-impact ads that speak directly to your audience. Our blend of creative flair and data-driven insights ensures your ads grab attention and generate results.
    </Accordion.Body>
  </Accordion.Item>

  {/* FAQ 5 */}
  <Accordion.Item eventKey="4">
    <Accordion.Header>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>05.</span> How quickly can I see results?
    </Accordion.Header>
    <Accordion.Body>
      It depends on your goals and the services you choose. That said, our strategic approach at <span style={{ color: '#f1f7f6' }}>Conversion Lab</span> is designed to deliver visible improvements early on. From there, we continually optimize to ensure your business keeps growing—month after month.
    </Accordion.Body>
  </Accordion.Item>

  {/* FAQ 6 */}
  <Accordion.Item eventKey="5">
    <Accordion.Header>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>06.</span> Can you handle brand management and social media too?
    </Accordion.Header>
    <Accordion.Body>
      Absolutely! We understand that building a strong, consistent brand presence is crucial for long-term success. Our team at <span style={{ color: '#f1f7f6' }}>Conversion Lab</span> will help define your brand’s voice, create engaging content, and manage your online reputation—so you can focus on what you do best.
    </Accordion.Body>
  </Accordion.Item>

  {/* FAQ 7 */}
  <Accordion.Item eventKey="6">
    <Accordion.Header>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>07.</span> Do I need to sign a long-term contract?
    </Accordion.Header>
    <Accordion.Body>
      No long-term contracts here! We believe in earning your trust every month. With <span style={{ color: '#f1f7f6' }}>Conversion Lab</span>, you’ll see the value in our work from the get-go, making a contract redundant. You stay because we deliver results, not because you’re locked in.
    </Accordion.Body>
  </Accordion.Item>

  {/* FAQ 8 */}
  <Accordion.Item eventKey="7">
    <Accordion.Header>
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>08.</span> Is it worth the investment if I’m a small business?
    </Accordion.Header>
    <Accordion.Body>
      100%. Investing in professional services can accelerate your growth and save you from costly mistakes down the line. <span style={{ color: '#f1f7f6' }}>Conversion Lab</span> works with businesses of all sizes, tailoring our approach to fit your budget and goals. We make every pound count.
    </Accordion.Body>
  </Accordion.Item>
</Accordion>


        </div>
        
    </div>
    </div>
  );
};

export default FourStageProcess;
