import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 - Page Not Found | Conversion Lab</title>
        <meta name="description" content="The page you're looking for cannot be found. Return to the homepage or check the URL." />
      </Helmet>
      <div className="container text-center py-5">
        <h1>404 - Page Not Found</h1>
        <p>Oops! The page you're looking for doesn't exist. It might have been moved or deleted.</p>
        <Link to="/" className="btn btn-primary">Go Back Home</Link>
      </div>
    </>
  );
};

export default NotFound;
