import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="container policies py-5">
      <h1>Cookie Policy</h1>
      <p>
        At ConversionLab, we use cookies and similar technologies to enhance your experience, analyze site traffic, and personalize content. This Cookie Policy explains what cookies are, how we use them, and how you can control them.
      </p>

      <h2>1. What Are Cookies?</h2>
      <p>
        Cookies are small text files that are stored on your device (computer, tablet, smartphone) when you visit websites. Cookies contain information that is transferred to your device's hard drive. They help websites recognize your device and store preferences such as login information, language settings, and other customizations.
      </p>

      <h2>2. Types of Cookies We Use</h2>
      <p>
        We use both first-party and third-party cookies on our site:
      </p>
      <ul>
        <li><strong>Essential Cookies:</strong> These cookies are necessary for the proper functioning of our website. They enable basic features such as page navigation and access to secure areas of the site.</li>
        <li><strong>Performance Cookies:</strong> These cookies collect information about how visitors use our site, such as which pages are visited most often. This helps us improve the performance and usability of our website.</li>
        <li><strong>Functional Cookies:</strong> These cookies allow us to remember your choices (such as language or region preferences) and provide enhanced, more personalized features.</li>
        <li><strong>Targeting/Advertising Cookies:</strong> We use these cookies to deliver relevant advertisements to you. They help us track the effectiveness of our marketing campaigns and show ads based on your browsing habits.</li>
      </ul>

      <h2>3. Third-Party Cookies</h2>
      <p>
        We may also allow third-party service providers to place cookies on your device to assist with analytics, advertising, and content delivery. These third parties may collect information about your interactions with our website and other websites. Examples include Google Analytics and Facebook Pixel.
      </p>

      <h2>4. How We Use Cookies</h2>
      <p>
        We use cookies to:
      </p>
      <ul>
        <li>Remember your preferences and settings.</li>
        <li>Provide enhanced features and personalization.</li>
        <li>Measure and analyze the effectiveness of our services.</li>
        <li>Display relevant advertising and marketing messages.</li>
      </ul>

      <h2>5. Your Choices Regarding Cookies</h2>
      <p>
        You can control or delete cookies as you wish. Most browsers allow you to refuse or accept cookies through their settings. You can also delete all cookies that are already stored on your device. However, please note that disabling cookies may affect the functionality of certain parts of our website.
      </p>

      <h2>6. Managing Cookies</h2>
      <p>
        You can manage your cookie preferences using the settings in your browser. Here are links to the cookie management instructions for some popular browsers:
      </p>
      <ul>
        <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a></li>
        <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a></li>
        <li><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">Safari</a></li>
        <li><a href="https://support.microsoft.com/en-us/topic/how-to-delete-cookies-in-microsoft-edge-dca31ba6-6e22-c9ff-fd43-8b5454f53205">Microsoft Edge</a></li>
      </ul>

      <h2>7. Updates to This Cookie Policy</h2>
      <p>
        We may update this Cookie Policy from time to time. We will post any changes on this page and, if significant, provide a more prominent notice (e.g., by email or a pop-up on the website).
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about our use of cookies or this Cookie Policy, please contact us at: <strong>studio@conversionlab.io</strong>.
      </p>
    </div>
  );
};

export default CookiePolicy;
