import React from 'react';
import AllServices from '../components/AllServices';

const Services = () => {
  return (
    <div>
      <div className='page-header pb-5'>
        <div className=' text-center' style={{ padding: '100px 0px 150px 0px' }}       >
             <h1>Discover Our Services</h1>
        </div>
      </div>
      <AllServices />
    </div>
    
  );
};

export default Services;
