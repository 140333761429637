import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div class="container policies py-5">
  <h1>Privacy Policy</h1>
  <p>We value your privacy and are committed to protecting your personal data. This privacy policy outlines how we collect, use, and safeguard your information, ensuring transparency and compliance with data protection regulations like the GDPR and CCPA.</p>

  <h2>1. Introduction</h2>
  <p>This Privacy Policy applies to all personal data collected through our website, services, and any related applications (“Services”). By using our Services, you agree to the collection and use of your information as described in this policy. If you have any questions or concerns, feel free to reach out to us at: <strong>studio@conversionlab.io</strong>.</p>

  <h2>2. Information We Collect</h2>
  <p>We may collect and process the following types of information:</p>
  <ul>
    <li><strong>Personal Information:</strong> Name, email address, phone number, business details, and any other information provided through forms or contact inquiries.</li>
    <li><strong>Usage Data:</strong> Details of your visits to our site, including IP addresses, browser type, operating system, referral source, and pages viewed.</li>
    <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to enhance user experience and analyze site traffic.</li>
  </ul>

  <h2>3. How We Use Your Information</h2>
  <p>We use the information collected for the following purposes:</p>
  <ul>
    <li><strong>To Provide Services:</strong> To respond to inquiries, deliver services, and manage client accounts.</li>
    <li><strong>To Improve User Experience:</strong> Analyze usage data to optimize and improve our website and services.</li>
    <li><strong>Marketing Communications:</strong> With your consent, we may send newsletters or promotional content. You can opt-out at any time.</li>
  </ul>

  <h2>4. Sharing Your Information</h2>
  <p>We may share your information with third-party service providers to help us operate our business, including:</p>
  <ul>
    <li><strong>Hosting Providers:</strong> For website hosting and data storage.</li>
    <li><strong>Marketing Partners:</strong> To assist in delivering targeted ads based on your preferences and activities.</li>
    <li><strong>Legal Compliance:</strong> If required by law, we may disclose your information to comply with legal obligations or protect our rights.</li>
  </ul>

  <h2>5. Your Rights Over Your Data</h2>
  <p>You have the following rights regarding your data:</p>
  <ul>
    <li><strong>Access:</strong> Request a copy of the personal data we hold about you.</li>
    <li><strong>Correction:</strong> Ask to update or correct your information if it is inaccurate.</li>
    <li><strong>Deletion:</strong> Request the deletion of your personal data under certain conditions.</li>
    <li><strong>Opt-Out:</strong> Unsubscribe from marketing communications at any time.</li>
  </ul>

  <h2>6. Data Security</h2>
  <p>We implement technical and organizational measures to protect your data from unauthorized access, disclosure, or misuse. This includes encryption, secure servers, and access control mechanisms. However, no data transmission is 100% secure, and we cannot guarantee absolute security.</p>

  <h2>7. Cookies and Tracking Technologies</h2>
  <p>We use cookies to enhance user experience and analyze site traffic. You can adjust your browser settings to refuse cookies, but some parts of our site may not function properly without them. Learn more about our cookie policy <a href="/cookie-policy">here</a>.</p>

  <h2>8. International Data Transfers</h2>
  <p>If you access our services from outside the UK, your information may be transferred internationally. We take steps to ensure that data transfers comply with applicable data protection laws, such as the GDPR.</p>

  <h2>9. Data Retention</h2>
  <p>We retain your personal data only for as long as necessary to fulfill the purposes outlined in this policy. When no longer required, data is securely deleted or anonymized.</p>

  <h2>10. Updates to This Policy</h2>
  <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and if significant, we will provide a more prominent notice.</p>

  <h2>11. Contact Us</h2>
  <p>If you have any questions about this Privacy Policy or wish to exercise your rights, please contact us at: <strong>studio@conversionlab.io</strong>.</p>
</div>

  );
};

export default PrivacyPolicy;
