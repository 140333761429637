import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Contact.css';
import '../css/Main.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from 'emailjs-com';
import arrowup from '../assets/arrow-trend-up-solid.svg';

const Contact = () => {
    useEffect(() => {
      AOS.init({
        once: true,
      });
    }, []);
  
    useEffect(() => {
      emailjs.init('ompVk1AnXGAd0Yr2Q'); // Replace with your actual EmailJS user ID
    }, []);
  
    // Form state
    const [formData, setFormData] = useState({
      name: '',
      phone: '',
      email: '',
      message: '',
    });
  
    // States for managing errors, success, and loading
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
  
    // Handle input changes
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    // Validate the form fields
    const validateForm = () => {
      let formErrors = [];
  
      if (!formData.name.trim()) {
        formErrors.push('Name is required.');
      }
      if (!/^[0-9]{10,11}$/.test(formData.phone)) {
        formErrors.push('Phone number must be 10-11 digits long.');
      }
      if (!/\S+@\S+\.\S+/.test(formData.email)) {
        formErrors.push('Please enter a valid email address.');
      }
      if (!formData.message.trim()) {
        formErrors.push('Message cannot be empty.');
      }
  
      setErrors(formErrors);
      return formErrors.length === 0;
    };
  
    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      setErrors([]);
      setSuccess('');
      setLoading(true); // Start loading spinner
  
      if (validateForm()) {
        const serviceID = 'service_ny9w22r';
        const adminTemplateID = 'template_gmmvryn';
        const userTemplateID = 'template_6pbv1vm';
        const userID = 'ompVk1AnXGAd0Yr2Q';
  
        // Send email to admin
        emailjs
          .send(serviceID, adminTemplateID, formData, userID)
          .then(
            () => {
              // Send confirmation email to the user
              emailjs
                .send(serviceID, userTemplateID, { ...formData, to_email: formData.email }, userID)
                .then(() => {
                  setSuccess('Thank you for reaching out! We have received your message.');
                  setLoading(false); // Stop loading
                })
                .catch((error) => {
                  setErrors(['Failed to send confirmation email.']);
                  setLoading(false); // Stop loading
                  console.error('Error sending confirmation email:', error);
                });
            },
            (error) => {
              setErrors(['Failed to send message. Please try again later.']);
              setLoading(false); // Stop loading
              console.error('Error sending email:', error);
            }
          );
  
        // Reset form fields
        setFormData({
          name: '',
          phone: '',
          email: '',
          message: '',
        });
      } else {
        setLoading(false); // Stop loading if validation fails
      }
    };

  return (
    <div className=' pt-5 pb-5'>
      <div className='container'>
        <div className='row'>

        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 mb-1 contact-text d-flex align-items-center mb-5' data-aos="fade-up" data-aos-delay="00">
            <div className="text-center w-100">
                <h1>Let's</h1>
                <h1><span>Talk!</span></h1>
            </div>
        </div>

        


        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12  col-12 mb-1' data-aos="fade-up" data-aos-delay="00">
          <div className='contact-form'>
        {/* Enhanced Error Messages */}
        {errors.length > 0 && (
            <div className="alert alert-danger text-left" role="alert">
            <strong>Please fix the following issues:</strong>
            <ul className="mb-0">
                {errors.map((error, index) => (
                <li key={index} className="mt-2">{error}</li>
                ))}
            </ul>
            </div>
        )}

        {/* Success Message */}
        {success && (
            <div className="alert alert-success text-left" role="alert">
            {success}
            </div>
        )}

        {/* Contact Form */}
        <form onSubmit={handleSubmit}>
            {/* Name Field */}
            <div className="form-group mb-3">
            <input
                required
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name*"
            />
            </div>

            {/* Phone and Email Fields (50/50 Split) */}
            <div className="form-row row">
            <div className="form-group col-md-6 mb-3">
                <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                    <span role="img" className="phone-emoji" aria-label="UK Flag">🇬🇧</span> +44
                    </span>
                </div>
                <input
                    required
                    type="tel"
                    className="form-control phone-control"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="7123 123456*"
                />
                </div>
            </div>

            <div className="form-group col-md-6 mb-3">
                <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                />
            </div>
            </div>

        {/* Service and Budget Fields (50/50 Split) */}
        <div className="form-row row">
            {/* Service Dropdown */}
            <div className="form-group col-md-6 mb-3 custom-dropdown position-relative">
                <select
                className="form-control dropdown-input"
                id="service"
                name="service"
                value={formData.service}
                onChange={handleChange}
                required
                >
                <option value="" hidden className="placeholder-option">What service do you need?</option>
                <option value="Web Design">Web Design</option>
                <option value="SEO">SEO</option>
                <option value="Google Ads">Google Ads</option>
                <option value="Facebook Ads">Facebook Ads</option>
                <option value="Social Media Management">Social Media Management</option>
                <option value="Branding">Branding</option>
                <option value="Other">Other</option>
                </select>
                {/* Custom Dropdown Arrow */}
                <span className="dropdown-arrow position-absolute">
                <svg width="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </div>

            {/* Budget Dropdown */}
            <div className="form-group col-md-6 mb-3 custom-dropdown position-relative">
                <select
                className="form-control dropdown-input"
                id="budget"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                required
                >
                <option value="" hidden className="placeholder-option">What's your budget?</option>
                <option value="£0-£500">£0-£500</option>
                <option value="£500-£1000">£500-£1000</option>
                <option value="£1000-£2000">£1000-£2000</option>
                <option value="£2k-£5k">£2k-£5k</option>
                <option value="£5k-£10k">£5k-£10k</option>
                <option value="£10k+">£10k+</option>
                </select>
                {/* Custom Dropdown Arrow */}
                <span className="dropdown-arrow position-absolute">
                <svg width="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 </svg>
                </span>
            </div>
            </div>


            {/* Message Field */}
            <div className="form-group mb-3">
            <textarea
                required
                className="form-control"
                id="message"
                name="message"
                rows="6"
                value={formData.message}
                style={{ resize: 'none' }}
                onChange={handleChange}
                placeholder="Let us know what your project is about"
            ></textarea>
            </div>

            {/* Submit Button */}
            <button 
                style={{width:'100%'}}
                type="submit" 
                className="btn text-center m-auto d-flex justify-content-center align-items-center"
                disabled={loading}
                >
                {loading ? (
                    'Sending...'
                ) : (
                    <>
                    Send message 
                    <img src={arrowup} width="15px" alt='arrow-icon' className="ms-2"/>
                    </>
                )}
                </button>

        </form>
        </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
