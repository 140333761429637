import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

const Confirmation = () => {
  const location = useLocation();

  useEffect(() => {
    // Send event to Google Analytics
    if (window.ga) {
      window.ga('send', 'event', 'Meeting', 'Scheduled', 'User scheduled a meeting');
    }

    // Send event to Facebook Pixel
    if (window.fbq) {
      window.fbq('track', 'Lead');
    }
  }, []);

  return (
    <div className="confirmation" style={{ padding: '50px 20px', textAlign: 'center' }}>
      <h1>Thank You for Scheduling!</h1>
      <p>Your meeting has been successfully scheduled.</p>
      <p>We look forward to speaking with you!</p>
      <p>If you need to make changes to your appointment, you will receive an email confirmation shortly to do so.</p>
      <Link
                            to='/'
                            className="btn"
                          >
                            GO BACK HOME
                          </Link>
    </div>
  );
};

export default Confirmation;
