import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import '../css/About.css' 
import '../css/Main.css' 
import { Accordion } from 'react-bootstrap';

import arrowup from '../assets/arrow-trend-up-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Services = () => {
  useEffect(() => {
    AOS.init({
      once: true, 
    });
  }, []);
  return (
    <div>
      <div className='container pb-5'>
        <div className='about-hero'>
          <h2>Hey There! Welcome to <span>Conversion Lab</span></h2>
        </div>
      </div>

      <div className='container about-title'>
            <div className='row'>
                <div  data-aos="" className='col-12 text-lg-start text-md-start text-center mb-3'>
                <h1><span className='span-tag2'>WHO WE ARE</span>About Conversion Lab</h1>
                </div>
            </div>
        </div>

      <div className='container' data-aos="fade-up" data-aos-delay="00">
        <div className='row'>
          <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 about-text'>
            <p>
              Let's get acquainted! We're not your average digital marketing agency - we're a team of passionate individuals who eat, sleep, and breathe <span style={{ color: '#f1f7f6' }}>creativity, innovation, and all things digital</span>. At{' '}
              <span style={{ color: '#f1f7f6' }}>Conversion Lab</span>, we're on a mission to make your online <span style={{ color: '#00DF81' }}>dreams come true</span>, one pixel at a time! We're a bunch of{' '}
              <span style={{ color: '#f1f7f6' }}>tech-savvy wizards</span>,{' '}
              <span style={{ color: '#f1f7f6'}}>design enthusiasts</span>, and{' '}
              <span style={{ color: '#f1f7f6'}}>social media mavens</span> who believe that digital marketing should be fun, exciting, and downright awesome.
            </p>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 about-text'>
          <Accordion defaultActiveKey="0">
            {/* Our History Section */}
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span style={{ fontWeight: 'bold', marginRight: '10px' }}>01.</span> Our History
              </Accordion.Header>
              <Accordion.Body>
                Conversion Lab started with a simple goal: to help businesses build meaningful digital experiences. From a small team of enthusiasts to a full-fledged agency, our journey has been shaped by innovation, growth, and client success.
              </Accordion.Body>
            </Accordion.Item>

            {/* Our Mission Section */}
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <span style={{ fontWeight: 'bold', marginRight: '10px' }}>02.</span> Our Mission
              </Accordion.Header>
              <Accordion.Body>
                Our mission is to transform businesses by creating high-performing digital strategies that drive growth. We aim to elevate your brand and make your business stand out in a crowded digital space.
              </Accordion.Body>
            </Accordion.Item>

            {/* Our Vision Section */}
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <span style={{ fontWeight: 'bold', marginRight: '10px' }}>03.</span> Our Vision
              </Accordion.Header>
              <Accordion.Body>
                We envision a world where every business has access to exceptional digital tools and strategies, enabling them to compete on a global stage. At Conversion Lab, we’re building the future of digital marketing.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
