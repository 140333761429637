import React, { useEffect  } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import '../css/Portfolio.css' 
import '../css/Main.css' 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Convertlab from '../assets/Portfolio/convertlab-main.png'
import Dalle from '../assets/Portfolio/dalle-designs-main.png'
import Gluteflex from '../assets/Portfolio/gluteflex-main.png'
import Nosnore from '../assets/Portfolio/nosnore-main.png'


const Portfolio = () => {
    useEffect(() => {
        AOS.init({
          once: true, 
        });
      }, []);

  return (
    <div className='portfolio pt-5 pb-5'>
        
        <div className='container'>
            <div className='row'>
                <div  data-aos="" className='col-12 text-lg-start text-md-start text-center mb-3'>
                <h1><span className='span-tag'>WORKS</span>Case studies</h1>
                </div>
            </div>
        </div>

        <div className='container portfolio-row'>

            <div className='row '>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 p-0 portfolio-box-padding' >
                    <Link to="/no-snore" className="portfolio-link">
                        <div className='portfolio-box'  data-aos="fade-up" data-aos-delay="300">
                            <div className='portfolio-box-img'>
                                <img src={Nosnore} width="100%" alt="Nosnore Portfolio Image"></img>
                            </div>
                            <div className='portfolio-box-text'>
                                <span className='span-tag2'>CASE STUDY</span>
                                <h3>Shopify Design & Ad Campaigns for No-Snore</h3>
                                <p>How our Shopify design and targeted Meta and Google Ads drove £500k in sales within 9 months.</p>
                                <div className='portfolio-box-stats'>
                                    <div className='portfolio-stat'>
                                        <h2>£500k+</h2>
                                        <p>Revenue generated</p>
                                    </div>
                                    <div className='portfolio-stat'>
                                        <h2>50%</h2>
                                        <p>Increase in conversion</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>

                        <Link to="/glute-flex" className="portfolio-link"  data-aos="fade-up" data-aos-delay="150">
                        <div className='portfolio-box mt-5'>
                            <div className='portfolio-box-img'>
                                <img src={Gluteflex} width="100%" alt="Gluteflex Portfolio Image"></img>
                            </div>
                            <div className='portfolio-box-text'>
                                <span className='span-tag2'>CASE STUDY</span>
                                <h3>Shopify Design and Meta Ads Management for GluteFlex</h3>
                                <p>Our CRO-focused Shopify store and strategic Meta Ads drove £25k in sales within the first month, maximising conversions for this fitness tool.</p>
                                <div className='portfolio-box-stats'>
                                    <div className='portfolio-stat'>
                                        <h2>20%</h2>
                                        <p>Increase in conversion rate</p>
                                    </div>
                                    <div className='portfolio-stat'>
                                        <h2>2X</h2>
                                        <p>Increase in meta ROAS</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>

                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 p-0 portfolio-box-padding mt-5 '>
                    <Link to="/dalle-designs" className="portfolio-link">
                        <div className='portfolio-box'  data-aos="fade-up" data-aos-delay="150">
                            <div className='portfolio-box-img'>
                                <img src={Dalle} width="100%" alt="Dall-E Designs Portfolio Image"></img>
                            </div>
                            <div className='portfolio-box-text'>
                                <span className='span-tag2'>CASE STUDY</span>
                                <h3>Fully Custom Web Design & Development for Dall-E Designs</h3>
                                <p>Bespoke PHP front-end and back-end solution built with MySQL, featuring Figma-designed interfaces, Stripe-powered checkout, and seamless OpenAI integration.</p>

                                <div className='portfolio-box-stats'>
                                    <div className='portfolio-stat'>
                                        <h2>60%</h2>
                                        <p>Increase in website traffic</p>
                                    </div>
                                    <div className='portfolio-stat'>
                                        <h2>100%</h2>
                                        <p>Increase in conversion rate</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                        

                        <Link to="/convertlab" className="portfolio-link">
                        <div className='portfolio-box mt-5'  data-aos="fade-up" data-aos-delay="00">
                            <div className='portfolio-box-img'>
                                <img src={Convertlab} width="100%" alt="ConvertLab Portfolio Image"></img>
                            </div>
                            <div className='portfolio-box-text'>
                                <span className='span-tag2'>CASE STUDY</span>
                                <h3>Custom Shopify Design for ConvertLab</h3>
                                <p>Tailored Shopify experience crafted with custom Liquid code to enhance user engagement and boost conversions.</p>

                                <div className='portfolio-box-stats'>
                                    <div className='portfolio-stat'>
                                        <h2>40%</h2>
                                        <p>Increase in conversion rate</p>
                                    </div>
                                    <div className='portfolio-stat'>
                                        <h2>70%</h2>
                                        <p>Increase in website traffic</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    
                     
                    </div>

            </div>

            <div className='brands-button text-center mt-5 pb-5'>
            <Link
                            data-aos="fade-up"
                            data-aos-delay="500"
                            to='/projects'
                            className="btn"
                          >
                            VIEW ALL PROJECTS <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={arrowup} width="20px " alt='calendar-icon'></img>
                          </Link>
            </div>

        </div>


    </div>

  );
};

export default Portfolio;
