import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const MassEmail = () => {
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [errors, setErrors] = useState([]);
  const [csvFile, setCsvFile] = useState(null);

  // Your EmailJS credentials
  const serviceID = 'service_ny9w22r';
  const templateID = 'template_q26i7g1'; // Updated Template ID
  const userID = 'ompVk1AnXGAd0Yr2Q';

  // Handle CSV file selection
  const handleFileUpload = (event) => {
    setCsvFile(event.target.files[0]);
  };

  // Function to manually parse CSV file using plain JavaScript
  const parseCsv = () => {
    if (!csvFile) return;

    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      const lines = text.split('\n').filter((line) => line.trim() !== '');
      const headers = lines[0].split(','); // ['email', 'name']

      // Extract data rows
      const parsedData = lines.slice(1).map((line) => {
        const values = line.split(',');
        let contact = {};
        headers.forEach((header, index) => {
          contact[header.trim()] = values[index] ? values[index].trim() : '';
        });
        return contact;
      });

      setCsvData(parsedData);
    };
    reader.readAsText(csvFile);
  };

  // Function to send emails to all contacts in the CSV
  const sendEmails = () => {
    if (csvData.length === 0) {
      setErrors([...errors, 'No data found in the CSV file.']);
      return;
    }

    setLoading(true);
    setSuccess('');
    setErrors([]);

    const promises = csvData.map((contact) => {
      const templateParams = {
        to_email: contact.email,
        name: contact.name,
      };

      return emailjs.send(serviceID, templateID, templateParams, userID);
    });

    // Execute all email promises
    Promise.all(promises)
    .then(() => {
      setSuccess(`Successfully sent emails to ${csvData.length} contacts!`);
      setLoading(false);
    })
    .catch((error) => {
      // Detailed error handling
      console.error('EmailJS error:', error);
      setErrors([`Failed to send some emails: ${error.text || error.message || 'Unknown error'}`]);
      setLoading(false);
    });
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Mass Email Sender</h1>
      {/* File Input */}
      <input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        style={{ marginBottom: '20px' }}
      />

      {/* Parse Button */}
      <button onClick={parseCsv} disabled={!csvFile} style={{ marginRight: '10px' }}>
        Parse CSV
      </button>

      {/* Send Emails Button */}
      <button onClick={sendEmails} disabled={csvData.length === 0 || loading}>
        {loading ? 'Sending Emails...' : 'Send Emails'}
      </button>

      {/* Show success message */}
      {success && <div style={{ color: 'green', marginTop: '20px' }}>{success}</div>}

      {/* Show error messages */}
      {errors.length > 0 && (
        <div style={{ color: 'red', marginTop: '20px' }}>
          <h3>Error:</h3>
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MassEmail;
