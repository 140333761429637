import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import '../css/FourStageProcess.css' 
import '../css/Main.css' 
import { Accordion } from 'react-bootstrap';

import arrowup from '../assets/arrow-trend-up-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const FourStageProcess = () => {
  useEffect(() => {
    AOS.init({
      once: true, 
    });
  }, []);
  return (
    <div className=' pb-5 mt-3'>
        <div className='container about-title'>
                <div className='row'>
                    <div  data-aos="" className='col-12 text-lg-end text-md-end text-center mb-3'>
                    <h1><span className='span-tag2'>HOW WE WORK</span>Our 4 Step Process</h1>
                    </div>
                </div>
            </div>

        <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto process-steps" data-aos="fade-up" data-aos-delay="00">
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 process-number text-end'>
                    <h2>01</h2>
                </div>
                <div className='col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12'>
                    <div className='process-box'>
                    <span className='span-tag2'>STEP 01</span>
                    <h4>Discovery Phase</h4>
                    <p>During this initial stage, we dive deep into understanding your business goals, target audience, and market landscape. Through comprehensive research and analysis, we identify key opportunities and challenges, setting the groundwork for a successful campaign.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto process-steps psteps2" data-aos="fade-up" data-aos-delay="00">
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 process-number text-end'>
                    <h2>02</h2>
                </div>
                <div className='col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12'>
                    <div className='process-box'>
                    <span className='span-tag2'>STEP 02</span>
                    <h4>Strategy Development</h4>
                    <p>With insights from the discovery phase, we develop a tailored strategy designed to achieve your unique objectives. This detailed plan includes essential tactics, platforms, and timelines needed to effectively hit your business targets.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto process-steps psteps2" data-aos="fade-up" data-aos-delay="00">
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 process-number text-end'>
                    <h2>03</h2>
                </div>
                <div className='col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12'>
                    <div className='process-box'>
                    <span className='span-tag2'>STEP 03</span>
                    <h4>Implementation and Execution</h4>
                    <p>With a solid strategy in place, our team brings it to life. We use advanced tools and techniques to execute each step with precision—whether it’s optimising your website, creating engaging social content, or crafting compelling visuals.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto process-steps psteps2" data-aos="fade-up" data-aos-delay="00">
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 process-number text-end'>
                    <h2>04</h2>
                </div>
                <div className='col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12'>
                    <div className='process-box'>
                    <span className='span-tag2'>STEP 04</span>
                    <h4>Monitoring and Optimisation</h4>
                    <p>Success doesn’t stop at execution. We continuously monitor performance metrics and analyse key data to identify areas for improvement, making adjustments as needed to maximise your results and deliver a strong return on investment.

</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default FourStageProcess;
