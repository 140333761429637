import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../css/Main.css'; 
import logo from '../assets/logo.png'; 
import logowhite from '../assets/logowhite.svg'; 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import { Link, useLocation } from 'react-router-dom';
import Calendar from '../assets/calendar.svg';
import { Accordion } from 'react-bootstrap';
import Check from '../assets/check.svg';
import FourStepProcess from '../components/FourStageProcess.js';
import NoSnore from '../assets/Portfolio/nosnore-main.png';

const BrandManagement = () => {
  return (
    <>
      <Helmet>
        <title>NoSnore | Conversion Lab</title>
        <meta name="description" content="" />
      </Helmet>
      <div className='portfolio-page'>
      <div className='page-header p-5'>
        <div className='row pt-1'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3'>
                <div className='portfolio-box-img'>
                    <img src={NoSnore} width="100%" alt="NoSnore Portfolio Image"></img>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3'>
            <div className='portfolio-box-text'>
                                <span className='span-tag2'>CASE STUDY</span>
                                <h3>Shopify Design & Ad Campaigns for No-Snore</h3>
                                <p>How our Shopify design and targeted Meta and Google Ads drove £500k in sales within 9 months.</p>
                                <div className='portfolio-box-stats'>
                                    <div className='portfolio-stat'>
                                        <h2>£500k+</h2>
                                        <p>Revenue generated</p>
                                    </div>
                                    <div className='portfolio-stat'>
                                        <h2>50%</h2>
                                        <p>Increase in conversion</p>
                                    </div>
                                </div>
                    </div>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3'>
                <div className='portfolio-box-text'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto'>
                            <div className=' text-center portfolio-page-desc'>
                                <p>No-Snore is a pioneering brand dedicated to helping couples achieve peaceful nights through innovative earplug solutions. With a commitment to quality and comfort, No-Snore has developed a range of high-performance earplugs specifically designed to block out snoring, allowing partners to enjoy uninterrupted sleep. Despite offering a unique solution, the brand faced challenges in effectively communicating its value and expanding its reach in a competitive market.</p>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>

        <div className='container pt-2 service-page-bg2 pb-4'>
                <div className='row'>
                    <div className='col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto'>
                    <div className="service-page-process">
                    <strong>Challenge</strong>
                    <li>The primary challenge was to effectively communicate the value of No-Snore earplugs to couples impacted by snoring. It was essential to create a compelling e-commerce platform that not only highlighted the problem but also positioned No-Snore as a reliable solution.</li>

                    <strong>Solution</strong>
                    <li>The approach to building the No-Snore brand involved several key strategies:</li>
                    
                    <strong>Brand Creation and Store Development:</strong>
                    <li>Developed a brand identity that reflected the brand’s mission to provide restful nights for couples. The Shopify store was designed with user experience at its core, ensuring it was visually appealing and easy to navigate.</li>
                    
                    <strong>Targeted Marketing Strategy:</strong>
                    <li>Focused on leveraging social media platforms, particularly Facebook and Instagram, to reach couples dealing with snoring. The marketing efforts were geared toward creating engaging content that spoke directly to the audience's pain points.</li>
                    
                    <strong>Mobile Optimization and Conversion Rate Focus:</strong>
                    <li>The website was optimized for mobile users, as a significant portion of the audience browsed via smartphones. Additionally, a strong emphasis was placed on conversion rate optimization, leading to a notable increase in sales.</li>

                    <strong>Results</strong>
                    <li>The outcomes of these efforts were remarkable:</li>
                    <li>The No-Snore brand scaled from £0 to £500,000 in sales within just 9 months, driven by effective marketing campaigns and a focus on conversions.</li>
                    <li>The conversion rate improved from 2.1% to over 4%, significantly enhancing the brand’s revenue potential and customer engagement.</li>
                    <li>The brand quickly became a trusted solution for couples facing the challenges of snoring, leading to increased market visibility and customer loyalty.</li>

                    <strong>Testimonial</strong>
                    <li>No-Snore has transformed the way couples approach snoring problems. The marketing strategy and website design have truly set us apart, and the growth we've experienced is a testament to the effectiveness of our approach. We’re now able to provide couples with the peaceful nights they deserve!</li>
                    <p>Jacob B, Founder of No-Snore</p>
                </div>
                    </div>
                </div>
              
          </div>

    </>
  );
};

export default BrandManagement;
