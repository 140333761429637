import React, { useState } from 'react';
import '../css/Navbar.css'; 
import '../css/Main.css'; 
import logo from '../assets/logo.png'; 
import logowhite from '../assets/logowhite.svg'; 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import { Link, useLocation } from 'react-router-dom';
import Calendar from '../assets/calendar.svg';


const Navbar = () => {
  const location = useLocation(); // Gets current URL path
  const currentPath = location.pathname; // Extracts the current path (e.g., '/about')
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false); // Close menu when a link is clicked
  };

  return (
    <>
    <div className='navbar-desktop desktop-only'>
      <div className='navbar-blur'>
      <div className='row'>
        <div className='col-3 m-auto'>
        <Link to='/' className='services-box-link'><img src={logowhite} alt='Logo' width="100%" /></Link>
        </div>
        <div className='col-9 text-end navbar-desktop-links'>
          <ul>
            <li>
              <Link to='/' className={currentPath === '/' ? 'active' : ''}>
                HOME
              </Link>
            </li>
            <li>
              <Link to='/about' className={currentPath === '/about' ? 'active' : ''}>
                ABOUT
              </Link>
            </li>
            <li>
              <Link to='/services' className={currentPath === '/services' ? 'active' : ''}>
                SERVICES
              </Link>
            </li>
            <li>
              <Link to='/projects' className={currentPath === '/projects' ? 'active' : ''}>
                PROJECTS
              </Link>
            </li>
            <li>
              <Link to='/reviews' className={currentPath === '/reviews' ? 'active' : ''}>
                REVIEWS
              </Link>
            </li>
            <li>
              <Link to='/contact' className={currentPath === '/contact' ? 'active' : ''}>
                CONTACT
              </Link>
            </li>
            <li>
              <Link to='/book-a-meeting' className="btn">
              Schedule a call <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='arrow-icon'></img>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      </div>
    </div>

      {/* Desktop & Mobile Navbar */}
      <nav className="navbar mobile-only" position='sticky'>
        <div className="container-fluid d-flex justify-content-between align-items-center">
          {/* Logo */}
          <a className="navbar-brand" href="/home">
          <Link to='/' className='services-box-link'><img src={logowhite} alt='Logo' width="250px" /></Link>
          </a>

          {/* Mobile Burger Icon */}
          <button className="burger-icon" onClick={toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>

      {/* Full-Screen Mobile Menu */}
      <div className={`mobile-nav ${menuOpen ? 'open' : ''}`}>
  <button className="close-btn" onClick={toggleMenu}>
    &times;
  </button>
  <div className="mobile-nav-content">
    {/* Logo on the left */}
    <div className="mobile-nav-logo">
    <Link to='/' className='services-box-link'><img src={logowhite} alt='Logo' width="250px" /></Link>
    </div>

    {/* Mobile Menu */}
    <ul className="mobile-menu">
    <li><Link to="/" onClick={closeMenu}>Home</Link></li> {/* Use Link for navigation */}
    <li><Link to="/about" onClick={closeMenu} >About</Link></li>
      <li><Link to="/services"onClick={closeMenu} >Services</Link></li>
      <li><Link to="/projects"onClick={closeMenu} >Projects</Link></li>
      <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>
      <li>
        <Link to="/book-a-meeting" className="btn" onClick={closeMenu}> {/* Button link to Contact */}
        Schedule a call <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='arrow-icon'></img>
        </Link>
      </li>
    </ul>

    {/* Contact info in the bottom-right */}
    <div className="contact-info">
        <h1>Contact Us</h1>
        <p>+44 7869 415861</p>
        <p>studio@conversionlab.io</p>
    </div>
  </div>
</div>

    </>
  );
};

export default Navbar;
