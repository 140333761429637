import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import '../css/Services.css' 
import '../css/Main.css' 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Services = () => {
  useEffect(() => {
    AOS.init({
      once: true, 
    });
  }, []);
  return (
    <div className='services pt-5 pb-2'>
        <div className="container mb-5">
          <div className='row'>
            <div  data-aos="fade-up" data-aos-delay="700" className='col-lg-6 col-md-12 col-sm-12 col-xs-12 text-lg-start text-md-start text-center'>
              <h1><span className='span-tag2'>SERVICES</span>What do we offer?</h1>
            </div>
            <div data-aos="fade-up" data-aos-delay="700" className='col-lg-6 col-md-12 col-sm-12 col-xs-12 text-lg-end text-md-end text-center mb-5'>
              <Link
                            data-aos="fade-up"
                            data-aos-delay="500"
                            to='/services'
                            className="btn"
                          >
                            VIEW ALL SERVICES <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={arrowup} width="20px " alt='calendar-icon'></img>
                          </Link>
            </div>
          </div>

          <div className='services-row'>
  <div className='row'>

    {/* SEO Service Box */}
    <div data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-in-sine" className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
      <Link to='/seo' className='services-box-link'>
        <div className='services-box'>

          <div className='services-box-icon mb-4'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
              <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/>
            </svg>
          </div>
          <h3 className='mb-3'>SEO</h3>
          <p className='mb-4'>
          We optimise your website’s structure, content, and performance to improve its ranking on search engines. This ensures that potential customers can find your business more easily, helping you attract the right audience and increase visibility online.
          </p>
          <div className='services-box-button mb-2'>
            <h5>LEARN MORE <span className='ms-2'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
            </span></h5>
          </div>


          
        </div>
      </Link>
    </div>

    {/* Web Design Service Box */}
    <div data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-in-sine" className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
      <a href='/web-design' className='services-box-link'>
        <div className='services-box'>
          <div className='services-box-icon mb-4'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>
          </div>
          <h3 className='mb-3'>Web Design & Development</h3>
          <p className='mb-4'>
          We create modern, high-converting websites tailored to your brand’s unique needs. From user-friendly designs to seamless functionality, we ensure your website not only looks great but also performs exceptionally, driving engagement and conversions.
          </p>
          <div className='services-box-button mb-2'>
            <h5>LEARN MORE <span className='ms-2'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
            </span></h5>
          </div>
        </div>
      </a>
    </div>

    {/* Ads Management Service Box */}
    <div data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-in-sine" className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
      <a href='/digital-marketing' className='services-box-link'>
        <div className='services-box'>
          <div className='services-box-icon mb-4'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64L0 400c0 44.2 35.8 80 80 80l400 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 416c-8.8 0-16-7.2-16-16L64 64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/></svg>
          </div>
          <h3 className='mb-3'>Digital Marketing</h3>
          <p className='mb-4'>
          We create and manage high-performing Google, Facebook, and Instagram ads that are strategically designed to reach your target audience. Our approach ensures that your ads generate meaningful engagement, drive traffic, and deliver measurable results for your business.
          </p>
          <div className='services-box-button mb-2'>
            <h5>LEARN MORE <span className='ms-2'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
            </span></h5>
          </div>
        </div>
      </a>
    </div>

    {/* Social Media Management Service Box */}
    <div data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-in-sine" className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
      <a href='/social-media' className='services-box-link'>
        <div className='services-box'>
          <div className='services-box-icon mb-4'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></div>
          <h3 className='mb-3'>Social Media</h3>
          <p className='mb-4'>
          We manage your social media presence by crafting engaging content that resonates with your audience. Our strategies are designed to build brand awareness, foster community interaction, and grow your following across platforms, ensuring your business stands out in the digital landscape.
          </p>
          <div className='services-box-button mb-2'>
            <h5>LEARN MORE <span className='ms-2'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
            </span></h5>
          </div>
        </div>
      </a>
    </div>

  </div>
</div>


        </div>
      </div>
  );
};

export default Services;
