import React from 'react';
import { Helmet } from 'react-helmet-async';
import AboutComp from '../components/About';
import Process from '../components/FourStageProcess';
import Faq from '../components/Faq';
import Contact from '../components/Contact';

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Conversion Lab</title>
        <meta name="description" content="Learn more about Conversion Lab and our mission to deliver high-quality web development and brand management solutions." />
      </Helmet>
      <AboutComp />
      <Process />
      <Faq />
      <Contact />
    </>
  );
};

export default About;
