import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Footer.css';
import '../css/Main.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from 'emailjs-com';
import arrowup from '../assets/arrow-trend-up-solid.svg';

const Footer = () => {
    useEffect(() => {
      AOS.init({
        once: true,
      });
    }, []);

  return (
    <div className='footer'>
      <div className='container'>
        <div className='footer-container ' data-aos="fade-up" data-aos-delay="00">
          <div className='row'>
            <div className='col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12'>



              <div className='row'>
                <div className='col-12'>
                  <div className='footer-left-side-box'>
                  <svg width="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 7.99999V13C16 13.7956 16.3161 14.5587 16.8787 15.1213C17.4413 15.6839 18.2043 16 19 16C19.7956 16 20.5587 15.6839 21.1213 15.1213C21.6839 14.5587 22 13.7956 22 13V12C21.9999 9.74302 21.2362 7.55247 19.8333 5.78452C18.4303 4.01658 16.4705 2.77521 14.2726 2.26229C12.0747 1.74936 9.76793 1.99503 7.72734 2.95936C5.68676 3.92368 4.03239 5.54995 3.03325 7.57371C2.03411 9.59748 1.74896 11.8997 2.22416 14.1061C2.69936 16.3125 3.90697 18.2932 5.65062 19.7263C7.39428 21.1593 9.57143 21.9603 11.8281 21.9991C14.0847 22.0379 16.2881 21.3122 18.08 19.94M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79085 9.79086 7.99999 12 7.99999C14.2091 7.99999 16 9.79085 16 12Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                    <h4>studio@conversionlab.io</h4>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='footer-left-side-box'>
                  <svg width="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.38028 8.85335C9.07627 10.303 10.0251 11.6616 11.2266 12.8632C12.4282 14.0648 13.7869 15.0136 15.2365 15.7096C15.3612 15.7694 15.4235 15.7994 15.5024 15.8224C15.7828 15.9041 16.127 15.8454 16.3644 15.6754C16.4313 15.6275 16.4884 15.5704 16.6027 15.4561C16.9523 15.1064 17.1271 14.9316 17.3029 14.8174C17.9658 14.3864 18.8204 14.3864 19.4833 14.8174C19.6591 14.9316 19.8339 15.1064 20.1835 15.4561L20.3783 15.6509C20.9098 16.1824 21.1755 16.4481 21.3198 16.7335C21.6069 17.301 21.6069 17.9713 21.3198 18.5389C21.1755 18.8242 20.9098 19.09 20.3783 19.6214L20.2207 19.779C19.6911 20.3087 19.4263 20.5735 19.0662 20.7757C18.6667 21.0001 18.0462 21.1615 17.588 21.1601C17.1751 21.1589 16.8928 21.0788 16.3284 20.9186C13.295 20.0576 10.4326 18.4332 8.04466 16.0452C5.65668 13.6572 4.03221 10.7948 3.17124 7.76144C3.01103 7.19699 2.93092 6.91477 2.9297 6.50182C2.92833 6.0436 3.08969 5.42311 3.31411 5.0236C3.51636 4.66357 3.78117 4.39876 4.3108 3.86913L4.46843 3.7115C4.99987 3.18006 5.2656 2.91433 5.55098 2.76999C6.11854 2.48292 6.7888 2.48292 7.35636 2.76999C7.64174 2.91433 7.90747 3.18006 8.43891 3.7115L8.63378 3.90637C8.98338 4.25597 9.15819 4.43078 9.27247 4.60655C9.70347 5.26945 9.70347 6.12403 9.27247 6.78692C9.15819 6.96269 8.98338 7.1375 8.63378 7.4871C8.51947 7.60142 8.46231 7.65857 8.41447 7.72538C8.24446 7.96281 8.18576 8.30707 8.26748 8.58743C8.29048 8.66632 8.32041 8.72866 8.38028 8.85335Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <h4>+44 1908 103740</h4>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='footer-left-side-box'>
                  <svg width="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 22C16 18 20 14.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 14.4183 8 18 12 22Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                    <h4>Office 10361, 182-184 High Street North East Ham, London</h4>
                  </div>
                </div>
              </div>



            </div>

            <div className='col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12'>



            <div className='row footer-links'>
                {/* Website Links */}
                <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 col-6 mb-3'>
                  <h5>Website</h5>
                  <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/projects">Projects</Link></li>
                    <li><Link to="/reviews">Reviews</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                  </ul>
                </div>

                {/* Services Links */}
                <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 col-6 mb-3'>
                  <h5>Services</h5>
                  <ul>
                    <li><Link to="/web-design">Web Design</Link></li>
                    <li><Link to="/seo">SEO</Link></li>
                    <li><Link to="/digital-marketing">Digital Marketing</Link></li>
                    <li><Link to="/social-media">Social Media</Link></li>
                    <li><Link to="/brand-management">Brand Management</Link></li>
                    <li><Link to="/ad-creation">Ad Creation</Link></li>
                  </ul>
                </div>

                {/* Boring Stuff Links */}
                <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 mb-3 boring-stuff'>
                  <h5>Boring stuff</h5>
                  <ul>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/cookie-policy">Cookie Policy</Link></li>
                    <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                  </ul>
                </div>
              </div>



            </div>


          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-12 copyright'>
            <p>© 2024 USECONVERSIONLAB LTD</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
