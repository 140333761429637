import React from 'react';
import { InlineWidget } from 'react-calendly';
import { useNavigate } from 'react-router-dom';

const BookMeeting = () => {
  const navigate = useNavigate();

  const handleEventScheduled = () => {
    // Redirect to confirmation page
    navigate('/confirmation');
  };

  return (
    <div className="book-meeting" style={{ padding: '50px 20px' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Book a Meeting</h1>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <InlineWidget 
          url="https://calendly.com/admin-conversionlab/30min" 
          styles={{ height: '700px', width: '100%' }} 
          onEventScheduled={handleEventScheduled}
        />
      </div>
    </div>
  );
};

export default BookMeeting;
