import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../css/Main.css'; 
import logo from '../assets/logo.png'; 
import logowhite from '../assets/logowhite.svg'; 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import { Link, useLocation } from 'react-router-dom';
import Calendar from '../assets/calendar.svg';
import { Accordion } from 'react-bootstrap';
import Check from '../assets/check.svg';
import FourStepProcess from '../components/FourStageProcess.js';
import Dalledesigns from '../assets/Portfolio/dalle-designs-main.png';

const DalleDesigns = () => {
  return (
    <>
      <Helmet>
        <title>Dall-e Designs | Conversion Lab</title>
        <meta name="description" content="" />
      </Helmet>
      <div className='portfolio-page'>
      <div className='page-header p-5'>
        <div className='row pt-1'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3'>
                <div className='portfolio-box-img'>
                <img src={Dalledesigns} width="100%" alt="Gluteflex Portfolio Image"></img>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3'>
            <div className='portfolio-box-text'>
                                <span className='span-tag2'>CASE STUDY</span>
                                <h3>Fully Custom Web Design & Development for Dall-E Designs</h3>
                                <p>Bespoke PHP front-end and back-end solution built with MySQL, featuring Figma-designed interfaces, Stripe-powered checkout, and seamless OpenAI integration.</p>

                                <div className='portfolio-box-stats'>
                                    <div className='portfolio-stat'>
                                        <h2>60%</h2>
                                        <p>Increase in website traffic</p>
                                    </div>
                                    <div className='portfolio-stat'>
                                        <h2>100%</h2>
                                        <p>Increase in conversion rate</p>
                                    </div>
                                </div>
                            </div>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3'>
                <div className='portfolio-box-text'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto'>
                            <div className=' text-center portfolio-page-desc'>
                            <p>DALL-E Designs is an innovative platform that empowers users to create custom garment designs using advanced AI technology. Developed as a university project, this platform leverages OpenAI's DALL-E to generate unique images based on user-submitted text prompts. By introducing a new level of personalization to e-commerce, DALL-E Designs aims to explore the potential of AI in the fashion and design industry.</p>
</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>

<div className='container pt-2 service-page-bg2 pb-4'>
    <div className="service-page-process">
    <div className='row'>
    <div className='col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto'>
    
    <strong>Challenge</strong>
    <li>The primary challenge was to merge AI technology with e-commerce effectively. While the vision was clear—creating a platform where users could generate unique clothing designs—implementing the necessary technologies posed difficulties.</li>
    
    <strong>Solution</strong>
    <li>The development of DALL-E Designs involved several strategic components:</li>

    <strong>Technology Integration:</strong>
    <li>Integrated the OpenAI API for generating images from user text prompts, enabling a dynamic design experience. The Stripe API was also integrated for secure payment processing, ensuring users could complete transactions confidently.</li>

    <strong>User-Centric Design:</strong>
    <li>The platform was built using Bootstrap for the frontend, PHP and MySQL for the backend, creating a responsive and smooth user experience. The design prioritized user interaction and ease of use, allowing customers to focus on creativity without technical hurdles.</li>

    <strong>Seamless Experience:</strong>
    <li>Ensured the seamless integration of these technologies through careful planning and testing, resulting in a functional platform where users could easily design and order their custom garments.</li>

    <strong>Results:</strong>
    <li>The successful implementation of DALL-E Designs yielded significant outcomes:</li>
    <li>Users can now generate personalized clothing designs effortlessly, enhancing engagement and satisfaction.</li>
    <li>The integration of AI has positioned DALL-E Designs as a forward-thinking platform in the e-commerce space, appealing to customers seeking innovative solutions in fashion.</li>

    <strong>Testimonial:</strong>
    <li>"DALL-E Designs has opened a new realm of possibilities for personalized fashion. The platform not only makes it easy for users to create custom designs but also showcases the transformative potential of AI in e-commerce. I'm excited about the future of this project!"</li>
    <p>[Client Name], Founder of DALL-E Designs</p>

    </div>
    </div>
    </div>
</div>


    </>
  );
};

export default DalleDesigns;
