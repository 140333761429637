import React, { useEffect  } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import '../css/Reviews.css' 
import '../css/Main.css' 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Convertlab from '../assets/Portfolio/convertlab-main.png';
import Dalle from '../assets/Portfolio/dalle-designs-main.png';
import Gluteflex from '../assets/Portfolio/gluteflex-main.png';
import Nosnore from '../assets/Portfolio/nosnore-main.png';
import star from '../assets/star-solid.svg';
import Oliver from '../assets/oliver.png';
import Jacob from '../assets/jacobb.jpeg';
import Charlie from '../assets/charlieg.jpeg';
import Belal from '../assets/belalk.jpeg';


const Reviews = () => {
    useEffect(() => {
        AOS.init({
          once: true, 
        });
      }, []);

  return (
    <div className='reviews pt-5'>
        
        <div className='container'>
            <div className='row'>
                <div  data-aos="" className='col-12 text-lg-end text-md-end text-center mb-3'>
                <h1><span className='span-tag2'>WHAT THEY RATE US</span>What our clients say</h1>
                </div>
            </div>
        </div>

        <div className='container pt-5 review-row '>

            <div className='row justify-content-center'>
                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                    <div className='review-box'>
                        <small data-aos="fade-up"><span>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px " alt='review-star'></img>
                        </span></small>
                        <div className='review-text'>
                            <p>Working with Conversion Lab was a game-changer for our brand. They took our vision for No-Snore and turned it into a reality, designing a stunning Shopify store that not only looks great but also converts like crazy. Their expertise in Meta and Google Ads allowed us to scale rapidly, achieving £500k in sales within just 9 months. The attention to detail and commitment to understanding our target audience really set them apart. I can confidently say that without Conversion Lab, we wouldn’t have reached this level of success.
                            </p>
                        </div>
                        <hr></hr>
                        <div className='review-avatar'>
                            <div className='review-avatar-box'>
                                <img src={Jacob} width={'45px'}></img>
                            </div>
                            <div className='review-avatar-box'>
                                <h4>Jacob B.</h4>
                                <p>CEO - NoSnore</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="150">
                    <div className='review-box'>
                        <small data-aos="fade-up"><span>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px " alt='review-star'></img>
                        </span></small>
                        <div className='review-text'>
                            <p>Conversion Lab’s team exceeded our expectations when it came to building the GluteFlex brand. From the Shopify store to the strategic Meta Ads, they created a powerful combination that drove £25k in sales within the first month! The increase in our conversion rate and return on ad spend (ROAS) was remarkable. They didn’t just focus on aesthetics — they understood our audience and crafted a digital experience that truly resonated. I’d recommend them to any brand looking to make a strong impact online.
                            </p>
                        </div>
                        <hr></hr>
                        <div className='review-avatar'>
                            <div className='review-avatar-box'>
                                <img src={Charlie} width={'45px'}></img>
                            </div>
                            <div className='review-avatar-box'>
                                <h4>Charlie G.</h4>
                                <p>CEO - GluteFlex</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-4' data-aos="fade-up" data-aos-delay="300">
                    <div className='review-box'>
                        <small data-aos="fade-up"><span>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px" alt='review-star'></img>
                                <img src={star} width="20px " alt='review-star'></img>
                        </span></small>
                        <div className='review-text'>
                            <p>I partnered with Conversion Lab to create a fully custom web solution for Dall-E Designs, and they nailed it! I needed a seamless integration with OpenAI’s tech and a smooth checkout process through Stripe, and they delivered beyond expectations. The bespoke PHP front-end and back-end solution they built not only boosted our site traffic by 60% but also doubled our conversion rate. I’m thrilled with the outcome, and I’m confident that Dall-E Designs now has a platform that’s truly one-of-a-kind.
                            </p>
                        </div>
                        <hr></hr>
                        <div className='review-avatar'>
                            <div className='review-avatar-box'>
                                <img src={Belal} width={'45px'}></img>
                            </div>
                            <div className='review-avatar-box'>
                                <h4>Belal K.</h4>
                                <p>CEO - Dall-E Designs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='brands-button text-center pb-5'>
            <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            GET YOUR FREE CONSULTATION <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={arrowup} width="20px " alt='calendar-icon'></img>
                          </Link>
            </div>


        </div>


    </div>

  );
};

export default Reviews;
