import React, { useEffect  } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import '../css/Why.css' 
import '../css/Main.css' 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Convertlab from '../assets/Portfolio/convertlab-main.png';
import Dalle from '../assets/Portfolio/dalle-designs-main.png';
import Gluteflex from '../assets/Portfolio/gluteflex-main.png';
import Nosnore from '../assets/Portfolio/nosnore-main.png';
import star from '../assets/star-solid.svg';
import Oliver from '../assets/oliver.png';


const Why = () => {
    useEffect(() => {
        AOS.init({
          once: true, 
        });
      }, []);

      const pills = [
        { title: 'Quality', icon: '🔧' },
        { title: 'Personalization', icon: '🎨' },
        { title: 'Reliability', icon: '✅' },
        { title: 'Collaboration', icon: '🤝' },
        { title: 'Innovation', icon: '💡' },
        { title: 'Customer Focus', icon: '📞' },
        { title: 'Trustworthiness', icon: '🛡️' },
        { title: 'Passion', icon: '🔥' },
        { title: 'Customer Support', icon: '📧' },
      ];

  return (
    <div className='why pt-5'>
        
        <div className='container '>
            <div className='row'>
                <div  data-aos="fade-up" data-aos-delay="00" className='col-12 text-lg-end text-md-end text-center mb-3'>
                <h1><span className='span-tag2'>WHY US</span>Why businesses choose us</h1>
                </div>
            </div>
            <div className='row'>
                <div  data-aos="fade-up" data-aos-delay="00" className='col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 text-lg-end text-md-end text-center mb-3 ms-auto'>
                <p>Our digital solutions agency is dedicated to driving real impact and showcasing powerful results. We don’t just build websites or run campaigns — we transform businesses. Our focus is on delivering measurable growth, backed by data-driven strategies that impress and inspire. We pride ourselves on turning concepts into success stories and our clients’ goals into eye-catching achievements.</p>
                </div>
            </div>
        </div>

        <div className='container why-row'>

            <div className='row ' data-aos="fade-up" data-aos-delay="00">
                <div className='col-lg-8 col-md-7  why-left-side-1'>
                    <div className='why-left-side-1-img'>
                    <h2>150%</h2>
                    <h4>AVERAGE TRAFFIC INCREASE</h4>
                    <p>Our clients choose to stay with us because we deliver great results and build strong, lasting relationships along the way.</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-5  why-right-side-outter mt-1'>
                    <div className='why-right-side-1'>
                    <div className='why-right-side-1-top'>
                        <h2>£2M+</h2>
                        <h4>REVENUE GENERATED</h4>
                    </div>
                    <div className='why-right-side-1-bottom'>
                        <p>We helped generate over £2M in revenue for our clients in the UK.</p>
                    </div>
                    </div>
                </div>
            </div>

            <div className='row mt-lg-3 mt-md-3 mt-sm-1 mt-xs-1 mt-1' data-aos="fade-up" data-aos-delay="00">
                <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12  why-left-side-1 mb-1'>
                    <div className='why-left-side-2-img text-start'>
                    <h2>99%</h2>
                    <h4>CLIENT RETENTION RATE</h4>
                    <p>Our exceptional service and results have fostered a 99% client retention rate, showcasing our commitment to building strong, lasting partnerships.</p>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12 why-right-side-outter'>
                    <div className='why-right-side-1'>
                    <div className='why-right-side-1-top text-end'>
                        <h4>ENHANCED BUSINESS VISIBILITY BY</h4>
                        <h2>80%</h2>
                    </div>
                    <div className='why-right-side-1-bottom text-end'>
                        <p>Our agency’s ongoing strategies have increased overall business visibility by 80%, consistently enhancing brand recognition and online presence.</p>
                    </div>
                    </div>
                </div>
            </div>


            <div className='brands-button text-center pb-5 pt-5'>
            <Link
                            data-aos="fade-up"
                            data-aos-delay="00"
                            to='/book-a-meeting'
                            className="btn"
                          >
                            GET YOUR FREE CONSULTATION <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={arrowup} width="20px " alt='calendar-icon'></img>
                          </Link>
            </div>

        </div>


    </div>

  );
};

export default Why;
